import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/orange.png"
import {IoMdArrowRoundBack} from "react-icons/io"
import firebase from './fire-base.js'
import Image from 'material-ui-image';
import verify from '../../images/verify.png'
import { NotificationManager } from 'react-notifications';
import { NotificationContainer } from 'react-notifications';
import {navigate} from "gatsby"

class VerifyComponent extends React.Component {

    sendVerifyEmail=()=>{
        console.log(firebase.auth().currentUser)
        firebase.auth().currentUser.sendEmailVerification().then(function() {
            NotificationManager.success("Successfully sent!.")
            NotificationManager.info("Please check your email.")
        }, function(error) {
            console.log(error)
            NotificationManager.error("Send verification email failed. Pleas try again.")
        });
    }

    componentDidMount() {
        if(this.getCurrentUser() === null || this.getCurrentUser === undefined ){
            navigate("/signin");
        }
    }

    getCurrentUser = ()=>{
        return firebase.auth().currentUser;
    }

  render() {

    return (
       <>
        <NotificationContainer />
        <div className = "auth-header"><Link to="/"><IoMdArrowRoundBack className="back-arrow"/></Link><img src = {Logo} className = "auth-logo"/></div>
        <div className = "auth-form-wrapper">
        <div className = "auth-form col-sm-6 col-md-6 col-lg-4" onSubmit={this.submit} >
            <div className="d-flex justify-content-center">
                <div style={{width: 180}} className="my-3">
                    <Image 
                        src={verify} 
                        aspectRatio = {1.2}
                    />
                </div>
            </div>
            <div className="py-4" style={{textAlign:"center", fontSize: 24, fontWeight:'bold'}}>Please verify youre email</div>
            <div className="d-flex justify-content-center">
                <p style={{maxWidth: 350, textAlign:'center', fontSize: 16}}>
                    We sent a verification email to <strong>info@cloudepin.it</strong>. Click the link in that email to start using Cloudpin.
                </p>
            </div>
            <div className="text-center">
                <span className = "text-center">Didn't get it? Check you spam folder or</span>
                <div className = "auth-link " style={{cursor:'pointer'}} onClick={this.sendVerifyEmail}>send again.</div>
            </div>
          </div>
        </div>
     </>
    )
  }
}

export default VerifyComponent