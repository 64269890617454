import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import VerifyComponent from "../components/auth/verify-component"

const VerifyPage = () => (
  
 <VerifyComponent></VerifyComponent>
  
)

export default VerifyPage
